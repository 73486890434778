/*! {!project_name!} v0.4.1 */
/**
 * CSS Stylesheet for: {!project_name!} v0.4.1
 *
 * Author: {!dev_alias!} @ {!dev_agency!}
 *
 * TOC:
 * 
 * CSS Custom properties
 * Plugins style's override
 * Base
 * Layout
 * * Header
 * * Sections
 * * Footer
 * Media queries
 * 
 * @todo Add each module/section needed
 * 
 */

/* CSS Custom Properties */
@import '_project_variables.css';


/* Plugins style's override */
body.sticky-footer .section {
  flex: 1 0 auto;
}
.notification.slide {
    width: 98%;
    top: 2%;
    left: 1%;
}
/* Base */
body {
  background-color: var(--complementary-color-2);
}
fieldset {
  border: none;
}
form label {
  font-family: var(--text-font);
  font-weight: normal;
  text-transform: uppercase;
  text-align: right;
  margin-right: 15px;
}

/* Layout */
.main-wrapper {
  height: 100vh;
}
header, .section {
  width: 100%;
}

/* * Header */
header {
}
.header-logo-wrapper {
  position: relative;
  text-align: center;
  margin: 0 auto;
  width: 360px;
  height: 90px;
  background-image: url(../img/logo-interni.svg);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 360px auto;
}
header nav.menu {
  height: 0;
  overflow: hidden;
}
a.main {
  text-transform: uppercase;
  line-height: 30px;
  margin: 0 4px;
}
.icon {
  display: inline-block;
  width: 40px;
  height: 40px;
  margin: 15px 5px 0 5px;
  border-radius: 50%;
  background-position: center;
  background-repeat: no-repeat;
}
.icon.facebook {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E7E0DA;%7D%0A%3C/style%3E%3Cpath class='st0' d='M20 0C9 0 0 9 0 20c0 11 9 20 20 20 11 0 20-9 20-20C40 9 31 0 20 0M25.9 12.1h-2.8c-1 0-1.2 0.4-1.2 1.4V16h4l-0.4 4.3h-3.6v12.9h-5.1V20.4h-2.7V16h2.7v-3.4c0-3.2 1.7-4.9 5.5-4.9h3.6V12.1z'/%3E%3C/svg%3E");
}
.icon.instagram {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 40 40'%3E%3Cstyle type='text/css'%3E .st0%7Bfill:%23E7E0DA;%7D%0A%3C/style%3E%3Cpath class='st0' d='M20 16.6c-2.1 0-3.9 1.7-3.9 3.9 0 2.1 1.7 3.9 3.9 3.9 2.1 0 3.9-1.7 3.9-3.9C23.9 18.3 22.1 16.6 20 16.6'/%3E%3Cpath class='st0' d='M29 13.6c-0.2-0.5-0.5-0.9-0.9-1.3 -0.4-0.4-0.8-0.7-1.3-0.9 -0.4-0.2-1-0.3-2.1-0.4 -1.2-0.1-1.6-0.1-4.7-0.1 -3.1 0-3.5 0-4.7 0.1 -1.1 0.1-1.7 0.2-2.1 0.4 -0.5 0.2-0.9 0.5-1.3 0.9 -0.4 0.4-0.7 0.8-0.9 1.3 -0.2 0.4-0.3 1-0.4 2.1 -0.1 1.2-0.1 1.6-0.1 4.7 0 3.1 0 3.5 0.1 4.7 0.1 1.1 0.2 1.7 0.4 2.1 0.2 0.5 0.5 0.9 0.9 1.3 0.4 0.4 0.8 0.7 1.3 0.9 0.4 0.2 1 0.3 2.1 0.4 1.2 0.1 1.6 0.1 4.7 0.1 3.1 0 3.5 0 4.7-0.1 1.1-0.1 1.7-0.2 2.1-0.4 0.5-0.2 0.9-0.5 1.3-0.9 0.4-0.4 0.7-0.8 0.9-1.3 0.2-0.4 0.3-1 0.4-2.1 0.1-1.2 0.1-1.6 0.1-4.7 0-3.1 0-3.5-0.1-4.7C29.4 14.6 29.2 14 29 13.6M20 26.4c-3.3 0-5.9-2.7-5.9-5.9 0-3.3 2.7-5.9 5.9-5.9 3.3 0 5.9 2.7 5.9 5.9C26 23.7 23.3 26.4 20 26.4M26.2 15.6c-0.8 0-1.4-0.6-1.4-1.4 0-0.8 0.6-1.4 1.4-1.4 0.8 0 1.4 0.6 1.4 1.4C27.6 15 27 15.6 26.2 15.6'/%3E%3Cpath class='st0' d='M20 0C9 0 0 9 0 20c0 11 9 20 20 20 11 0 20-9 20-20C40 9 31 0 20 0M31.5 25.2c-0.1 1.2-0.2 2.1-0.5 2.8 -0.3 0.8-0.7 1.4-1.3 2 -0.6 0.6-1.3 1-2 1.3 -0.7 0.3-1.6 0.5-2.8 0.5C23.6 32 23.2 32 20 32c-3.1 0-3.5 0-4.8-0.1 -1.2-0.1-2.1-0.3-2.8-0.5 -0.8-0.3-1.4-0.7-2-1.3 -0.6-0.6-1-1.3-1.3-2 -0.3-0.7-0.5-1.6-0.5-2.8 -0.1-1.2-0.1-1.6-0.1-4.8 0-3.1 0-3.5 0.1-4.8 0.1-1.2 0.3-2.1 0.5-2.8 0.3-0.8 0.7-1.4 1.3-2 0.6-0.6 1.3-1 2-1.3C13.2 9.2 14 9 15.3 8.9c1.2-0.1 1.6-0.1 4.8-0.1 3.1 0 3.5 0 4.8 0.1C26 9 26.9 9.2 27.6 9.5c0.8 0.3 1.4 0.7 2 1.3 0.6 0.6 1 1.3 1.3 2 0.3 0.7 0.5 1.6 0.5 2.8 0.1 1.2 0.1 1.6 0.1 4.8C31.6 23.6 31.6 24 31.5 25.2'/%3E%3C/svg%3E");
}


/* * Sections */

.section {
  background-color: var(--complementary-color-1);
}
.section article {
  width: 55%;
  margin: -100px auto 0 auto;
}
.heading {
  margin-top: 0;
  color: var(--text-color);
  text-transform: uppercase;
  font-weight: normal;
  letter-spacing: 2px;
}
.about-text {
  text-align: justify;
  -webkit-hyphens: auto;
  -ms-hyphens: auto;
  hyphens: auto;
}
input[type=text], textarea {
  background-color: transparent;
  border-color: var(--base-color);
  padding: 0 5px;
}
input[type=text]:focus, textarea:focus {
  background-color: var(--complementary-color-1-darker);
}
textarea {
  margin-bottom: 0;
}
input[type=text] {
  border: none;
  border-bottom: 1px solid var(--base-color);
}
a.back {
  font-size: 3.25rem;
  line-height: normal;
}
/* * Footer */
footer {
  position: absolute;
  bottom: 0;
  width: 100%;
	height: 80px;
  color: var(--complementary-color-2);
}


/* Animations */
/* background color change */
body {
  /* Animation */
          animation: bg-color-change .75s ease-in both;
  -webkit-animation: bg-color-change .75s ease-in both;
  animation-delay: 1.25s;
}
@-webkit-keyframes bg-color-change {
  0% {
    background-color: var(--complementary-color-2);
  }
  100% {
    background-color: var(--complementary-color-1);
  }
}
@keyframes bg-color-change {
  0% {
    background-color: var(--complementary-color-2);
  }
  100% {
    background-color: var(--complementary-color-1);
  }
}

/* Center content */
.main-wrapper > .column {
  -webkit-animation: center-content .25s ease-out both;
          animation: center-content .25s ease-out both;
  animation-delay: 1.5s;
}
@-webkit-keyframes center-content {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}
@keyframes center-content {
  0% {
    margin-top: 0;
  }
  100% {
    margin-top: -80px;
  }
}

/* Show nav */
header nav.menu {
  -webkit-animation: show-nav .25s ease-out both;
          animation: show-nav .25s ease-out both;
  animation-delay: 2s;
}
@-webkit-keyframes show-nav {
  0% {
    height: 1px;
  }
  100% {
    height: 100px;
  }
}
@keyframes show-nav {
  0% {
    height: 1px;
  }
  100% {
    height: 100px;
  }
}


/* Media queries */
@media (max-width: 1280px) {}
@media (max-width: 1140px) {}
@media (max-width: 1024px) {
  .grid, .grid.column {
    flex-flow: row wrap;
  }
}
@media (max-width: 980px) {}
@media (max-width: 780px) {}
@media (max-width: 640px) {

  .section article {
    width: 90%;
  }
  .main-wrapper > .column {
    margin-top: -180px;
  }
  footer {
    height: 100px;
  }
  footer p {
    margin-top: 0;
    margin-bottom: 0;
  }
  footer p.align-l, 
  footer p.align-r {
    text-align: center;
  }
  footer .grid, footer .grid.column {
    flex-flow: column;
  }
  footer .one-third.column {
    width: 100%;
  }

  .main-wrapper > .column {
    -webkit-animation: center-content .25s ease-out both;
            animation: center-content .25s ease-out both;
    animation-delay: 1.5s;
  }
  @-webkit-keyframes center-content {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: -160px;
    }
  }
  @keyframes center-content {
    0% {
      margin-top: 0;
    }
    100% {
      margin-top: -160px;
    }
  }
}
@media (max-width: 480px) {
  form label {
    font-size: .75rem;
  }
  .header-logo-wrapper {
    width: 300px;
    height: 68px;
    background-size: 300px auto;
  }

}